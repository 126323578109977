/* ButtonAnimation.css */
.button-clicked {
    animation: clickAnimation 0.4s ease;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
        background-color: #007bff;
        /* Default Bootstrap primary color */
    }

    50% {
        transform: scale(0.95);
        background-color: #0056b3;
        /* A darker shade for effect */
    }

    100% {
        transform: scale(1);
        background-color: #007bff;
    }
}